import {Box, NativeSelect, Typography, useMediaQuery, useTheme,} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {tokens} from "../theme";
import React, {useEffect, useState} from 'react';
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import L from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {useNavigate} from "react-router-dom";
import {
    getSystemMarkers,
    retrieveBarData,
    retrieveBarDataSimpleUser,
    retrievePieData,
    retrievePieDataSimpleUser,
    retrieveSystem
} from "../api/system";
import CustomBarChart from "../components/CustomBarChart";
import CustomPieChart from "../components/CustomPieChart";
import useAuth from "../hooks/useAuth";
import FormControl from "@mui/material/FormControl";
import {getTypes} from "../api/managePoints";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;
const Dashboard = () => {
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const colors = tokens(theme.palette.mode);
    const [markers, setMarkers] = useState([])
    const [initBarData, setInitBarData] = useState({})
    const [initPieData, setInitPieData] = useState({})
    const [types, setTypeOptions] = useState([])
    const [barXAxis] = useState(["Δευτέρα", "Τρίτη", "Τετάρτη", "Πέμπτη", "Παρασκευή", "Σάββατο", "Κυριακή"])
    const athensCenter = [40.26956, 22.50508]; // Center of Athens
    const zoom = 12; // Zoom level to focus on Athens
    const navigate = useNavigate();

    const {user, setUser} = useAuth()

    const handleChange = (event) => {
        getSystemMarkers(event.target.value)
            .then((systems) => {
                const marks = systems.data.flatMap(system => {
                    const coords = system.coordinates.replace(/\s+/g, '')
                    return {
                        prefix: system.serialPrefix,
                        position: coords.split(",").map(coord => parseFloat(coord))
                    };
                }).filter(mark => !(mark.position[0] === 0 && mark.position[1] === 0));
                setMarkers(marks)
            })
    };

    const getBarData = (value) => {
        let days = [];
        for (let i = 6; i >= 0; i--) {
            let date = new Date();
            date.setDate(date.getDate() - i);
            let dayIndex = (date.getDay() + 6) % 7;
            days.push( barXAxis[dayIndex]
            );
        }
        if (user?.role === 'admin' || user?.role === 'superadmin') {
            retrieveBarData(value)
                .then((res) => {
                    const data = Object.keys(res.data).reduce((result, typeKey) => {
                        result[typeKey] = days.map(day => {
                            return res.data[typeKey][day] !== undefined ? res.data[typeKey][day] : "0";
                        });
                        return result;
                    }, {});
                    setInitBarData(data)
                })
        } else {
            retrieveBarDataSimpleUser(value)
                .then((res) => {
                    const data = Object.keys(res.data).reduce((result, typeKey) => {
                        result[typeKey] = days.map(day => {
                            return res.data[typeKey][day] !== undefined ? res.data[typeKey][day] : "0";
                        });
                        return result;
                    }, {});
                    setInitBarData(data)
                })
        }
    }
    const getPieData = (value) => {
        if (user?.role === 'admin' || user?.role === 'superadmin') {
            retrievePieData(value, '', '', '', '', '','')
                .then((res) => {
                    const pieData = Object.entries(res.data).map(([name, value]) => {
                        return {value, name};
                    });
                    setInitPieData(pieData)
                })
        } else {
            retrievePieDataSimpleUser(value, '', '', '', '', '','')
                .then((res) => {
                    const pieData = Object.entries(res.data).map(([name, value]) => {
                        return {value, name};
                    });
                    setInitPieData(pieData)
                })
        }
    }
    const markerClick = (id) => {
        if (user?.role !== 'user') {
            navigate("/detailed/" + id);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user?.role === 'user') {
                    getTypes()
                        .then((res) => {
                            setTypeOptions(res.data.items);
                            getSystemMarkers(res.data.items[0].id)
                                .then((systems) => {
                                    const marks = systems.data.flatMap(system => {
                                        const coords = system.coordinates.replace(/\s+/g, '')
                                        return {
                                            prefix: system.serialPrefix,
                                            position: coords.split(",").map(coord => parseFloat(coord))
                                        };
                                    }).filter(mark => !(mark.position[0] === 0 && mark.position[1] === 0));
                                    setMarkers(marks)
                                })
                        });
                } else {
                    getTypes()
                        .then((res) => {
                            setTypeOptions(res.data.items);
                        })
                    const systems = await retrieveSystem()
                    const marks = systems.data.flatMap(item => {
                        return item.systems.map(system => {
                            const coords = system.coordinates.replace(/\s+/g, '')
                            return {
                                prefix: system.prefix,
                                position: coords.split(",").map(coord => parseFloat(coord))
                            };
                        });
                    });
                    setMarkers(marks)
                }
                await getBarData('0');
                await getPieData('d');
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [user]);

    return (
        <Box m="20px">
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                <Grid xs={12} sm={12} md={8} lg={8} container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid xs={12}>
                        <Box backgroundColor={colors.primary[400]}>
                            <Box p="0 30px" display="flex" justifyContent="space-between" alignItems="center">
                                <Box style={{width: '100%'}}>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="h5" fontWeight="600" style={{margin: '20px 0'}}
                                                    color={colors.grey[100]}>
                                            {user?.role === 'user' ? "Θέσεις Ανακύκλωσης" : "Χάρτης Συστημάτων"}
                                        </Typography>
                                        <Box flexGrow={1}/>
                                        {user?.role === 'user' && (
                                            <FormControl>
                                                <NativeSelect
                                                    onChange={handleChange}
                                                    inputProps={{
                                                        name: '',
                                                        id: 'uncontrolled-native',
                                                    }}
                                                >
                                                    {types.map((option) => {
                                                        return <option key={option.number} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    })}
                                                </NativeSelect>
                                            </FormControl>
                                        )}
                                    </Box>

                                    <MapContainer center={athensCenter} zoom={zoom}
                                                  style={{
                                                      height: '730px',
                                                      width: '100%',
                                                      borderRadius: '10px'
                                                  }}>
                                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                                        {markers.map((marker, index) => (
                                            <Marker key={marker.number} position={marker.position}
                                                    eventHandlers={{click: () => markerClick(marker.prefix)}}/>
                                        ))}
                                    </MapContainer>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid xs={12} sm={12} md={4} lg={4}
                      xl={4}>
                    <Box backgroundColor={colors.primary[400]} overflow="auto">
                        <Box display="flex" justifyContent="space-between" alignItems="center" color={colors.grey[100]}
                             borderBottom={`4px solid ${colors.primary[500]}`} p="15px"
                             style={{position: 'relative', overflow: 'auto'}}>
                            <CustomPieChart options={{'d': 'Ημέρας', 'w': 'Εβδομάδας', 'm': 'Μήνα', 'all': 'Όλα'}}
                                            initData={initPieData}
                                            getPieData={getPieData}
                                            subtitle={'(Συνολο Συστηματων)'}
                                            title={'Κατανομή Απορριμάτων'}/>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center"
                             color={colors.grey[100]} p="15px" style={{position: 'relative', overflow: 'auto'}}>
                            <CustomBarChart options={types}
                                            initData={initBarData}
                                            getBarData={getBarData}
                                            xAxis={barXAxis}/>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;
