import React, {useEffect, useRef, useState} from 'react';
import {MapContainer, Marker, TileLayer} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {Box, NativeSelect, Tooltip, Typography, useMediaQuery, useTheme} from "@mui/material";
import {tokens} from "../theme";
import Grid from "@mui/material/Unstable_Grid2";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Modal from 'react-bootstrap/Modal';
import {createMRTColumnHelper, MaterialReactTable, useMaterialReactTable} from "material-react-table";
import CustomModal from "./CustomModal";
import {MRT_Localization_EL} from "material-react-table/locales/el";
import {
    getHistoricalSystem,
    getSystemByPrefix,
    retrieveBarData,
    retrieveBarDataSimpleUser,
    retrievePieData,
    retrievePieDataSimpleUser,
    retrieveSystem,
    updateSystem
} from "../api/system";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import FormControl from "@mui/material/FormControl";
import CustomPieChart from "../components/CustomPieChart";
import CustomBarChart from "../components/CustomBarChart";
import useAuth from "../hooks/useAuth";
import * as echarts from "echarts";
import EditIcon from "@mui/icons-material/Edit";
import {getTypes} from "../api/managePoints";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

const columnHelper = createMRTColumnHelper();

L.Marker.prototype.options.icon = DefaultIcon;
const Detailed = ({setLoading}) => {
    const zoom = 15; // Zoom level to focus on Athens
    const [markers, setMarkers] = useState([]);

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const colors = tokens(theme.palette.mode);

    const [initBarData, setInitBarData] = useState({})
    const [athensCenter, setAthensCenter] = useState([37.9838, 23.7275])
    const [barXAxis] = useState(["Δευτέρα", "Τρίτη", "Τετάρτη", "Πέμπτη", "Παρασκευή", "Σάββατο", "Κυριακή"])
    const [data, setData] = useState([]);
    const [systems, setSystems] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [typeOptions, setTypeOptions] = useState([])

    const {user, setUser} = useAuth()
    const [initPieData, setInitPieData] = useState({})
    const navigate = useNavigate();
    const {id} = useParams();
    const [selectedSystem, setSelectedSystem] = useState(id);
    const [serialNum, setSerialNum] = useState(id);
    const chartRef = useRef(null);


    useEffect(() => {
        getSystemByPrefix(id)
            .then((res) => {
                //console.log(res.data)
                setData(res.data.systems)
         
                const trimCoords = res.data.coordinates.replace(/\s+/g, '')
                const marks = [{
                    position: trimCoords.split(",").map(parseFloat)
                }]
                // const marks = res.data.map(item => {
                //     const coords = item.coordinates.replace(/\s+/g, '')
                //     return {
                //         id: item._id,
                //         position: coords.split(",").map(parseFloat),
                //         name: item.location
                //     };
                // });
                setMarkers(marks)
                setAthensCenter(marks[0].position)

            })
            .catch((err) => {
                console.log(err)
            })

        retrieveSystem()
            .then((res) => {
                setSystems(res.data)
            })
            .finally(() => {
                setLoading(false);
            })
        getTypes()
            .then((r) => {
                setTypeOptions(r.data.items)
            })
        getPieData('d', id);
        getBarData('0', id);
    }, [id]);


    const CenterUpdater = ({ center }) => {
        const map = useMap();
        useEffect(() => {
            map.setView(center); // Update the map's center dynamically
        }, [center, map]); // Run this effect when center changes
        return null;
    };


    // const getBarData = (value) => {
    //     if (user?.role === 'admin' || user?.role === 'superadmin') {
    //         retrieveBarData(value, id)
    //             .then((res) => {
    //                 console.log("API Response Data:", res.data);

    //                 const data = Object.keys(res.data).reduce((result, typeKey) => {
    //                     result[typeKey] = barXAxis.map(day => {
    //                         return res.data[typeKey][day] !== undefined ? res.data[typeKey][day] : "0";
    //                     });
    //                     return result;
    //                 }, {});
    //                 setInitBarData(data)
    //             })
    //     } else {
    //         retrieveBarDataSimpleUser(value, id)
    //             .then((res) => {
    //                 console.log("API Response:", res.data); // Debugging
    //                 const data = Object.keys(res.data).reduce((result, typeKey) => {
    //                     result[typeKey] = barXAxis.map(day => {
    //                         return res.data[typeKey][day] !== undefined ? res.data[typeKey][day] : "0";
    //                     });
    //                     return result;
    //                 }, {});
    //                 setInitBarData(data)
    //             })
    //     }
    // }
    const getBarData = (value) => {
        let days = [];
        for (let i = 6; i >= 0; i--) {
            let date = new Date();
            date.setDate(date.getDate() - i);
            let dayIndex = (date.getDay() + 6) % 7;
            days.push(barXAxis[dayIndex]);
        }
    
        if (user?.role === 'admin' || user?.role === 'superadmin') {
            retrieveBarData(value, id)
                .then((res) => {
                    console.log("✅ API Response Data:", res.data);
                    console.log("📌 Computed days:", days);
    
                    const data = Object.keys(res.data).reduce((result, typeKey) => {
                        result[typeKey] = days.map(day => {
                            const value = res.data[typeKey][day] !== undefined ? res.data[typeKey][day] : "0";
                            console.log(`📅 Mapping Day: ${day} → Value: ${value}`);
                            return value;
                        });
    
                        return result;
                    }, {});
    
                    console.log("✅ Mapped Data Sent to Chart:", data);
                    setInitBarData(data);
                })
                .catch(err => console.error("❌ API Error:", err));
        } else {
            retrieveBarDataSimpleUser(value, id)
                .then((res) => {
                    console.log("✅ API Response Data:", res.data);
                    console.log("📌 Computed days:", days);
    
                    const data = Object.keys(res.data).reduce((result, typeKey) => {
                        result[typeKey] = days.map(day => {
                            const value = res.data[typeKey][day] !== undefined ? res.data[typeKey][day] : "0";
                            console.log(`📅 Mapping Day: ${day} → Value: ${value}`);
                            return value;
                        });
    
                        return result;
                    }, {});
    
                    console.log("✅ Mapped Data Sent to Chart:", data);
                    setInitBarData(data);
                })
                .catch(err => console.error("❌ API Error:", err));
        }
    };
    
    const getPieData = (value) => {
        if (user?.role === 'admin' || user?.role === 'superadmin') {
            retrievePieData(value, id, '','', '', '', '')
                .then((res) => {
                    const pieData = Object.entries(res.data).map(([name, value]) => {
                        return {value, name};
                    });
                    setInitPieData(pieData)
                })
        } else {
            retrievePieDataSimpleUser(value, id,'','','','','')
                .then((res) => {
                    const pieData = Object.entries(res.data).map(([name, value]) => {
                        return {value, name};
                    });
                    setInitPieData(pieData)
                })
        }
    }
    //
    // const openModal = (serial_num) => {
    //     setSerialNum(serial_num)
    //     setIsOpen(true)
    // }

    useEffect(() => {
        if (modalIsOpen) {
            getHistoricalSystem(serialNum)
                .then((res) => {
                    const formattedData = res.data.map(item => {
                        const date = new Date(item.timestamp);
                        const month = date.getMonth() + 1;
                        const day = date.getDate();

                        return {
                            timestamp: `${month}/${day}`,
                            weight: item.weight
                        };
                    });
                    const dates = formattedData.map(entry => entry.timestamp);
                    const values = formattedData.map(entry => entry.weight);

                    const historyChart = echarts.init(chartRef.current);
                    historyChart.setOption({
                        xAxis: {
                            type: 'category',
                            data: dates,
                        },
                        yAxis: {
                            type: 'value',
                        },
                        series: [{
                            data: values,
                            type: 'line',
                        }],
                    });
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(err.message)
                })
        }
    }, [modalIsOpen])

    const closeModal = () => {
        setIsOpen(false);
    }
    const columns = [
        columnHelper.accessor('serial_num', {header: 'Σειριακός Αριθμός', enableEditing: false, enableColumnFilter: false}),
        columnHelper.accessor('type', {header: 'Ανακυκλώσιμο Υλικό', enableColumnFilter: false}),
        columnHelper.accessor((row) => row.bin_weight_day.toFixed(1), {header: 'Ημερήσια ποσότητα (kg)', enableEditing: false, enableColumnFilter: false}),
        columnHelper.accessor((row) => row.bin_lock ? 'ΝΑΙ'  : 'ΟΧΙ', {header: 'Κάδος Πλήρης', enableEditing: false, enableColumnFilter: false}),
        columnHelper.accessor('time_locked', {header: 'Χρονική Διάρκεια Κλειδώματος', enableEditing: false, enableColumnFilter: false}),
        columnHelper.accessor('location', {header: 'Διεύθυνση (GPS)',enableEditing: false, enableColumnFilter: false}),
        columnHelper.accessor('location2', {header: 'Περιοχή', enableColumnFilter: false}),
        columnHelper.accessor('organisation', {header: 'Πόλη', enableColumnFilter: false}),
        columnHelper.accessor((row) => new Date(row.last_unlocked).toLocaleString('en-GB', {
            timeZone: 'Europe/Athens',
            hour12: false
        }), {
            header: 'Τελευταία Εκκένωση από Γεμάτο',
            id: 'last_unlocked',
            enableEditing: false
        }),
        columnHelper.accessor((row) => row.door_lock ? 'ΝΑΙ'  : 'ΟΧΙ', {header: 'Καπάκι Ανοιχτό',enableEditing: false, enableColumnFilter: false}),
        columnHelper.accessor('comments', {header: 'Σχόλια', enableColumnFilter: false}),
        columnHelper.accessor('status', {header: 'Κατάσταση Συστήματος', enableEditing: false, enableColumnFilter: false}),
        columnHelper.accessor((row) => new Date(row.last_comm).toLocaleString('en-GB', {
            timeZone: 'Europe/Athens',
            hour12: false
        }), {
            header: 'Τελευταία Επικοινωνία',
            id: 'last_comm',
            enableEditing: false, enableColumnFilter: false
        }),


        columnHelper.accessor((row) => new Date(row.updatedAt).toLocaleString('en-GB', {
            timeZone: 'Europe/Athens',
            hour12: false
        }), {
            header: 'Τελευταία Τροποποίηση',
            id: 'updatedAt',
            enableEditing: false
            , enableColumnFilter: false
        }),

        columnHelper.accessor('_id', {header: 'ID', enableEditing: false, enableColumnFilter: false})


    ];



    const table = useMaterialReactTable({
        columns,
        data,
        defaultDisplayColumn: {
            enableResizing: true
        },
        enableRowActions: true,
        enableEditing: true,
        editDisplayMode: 'row',
        positionActionsColumn: 'last',
        enableRowSelection: true,
        columnFilterDisplayMode: 'popover',
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        localization: {...MRT_Localization_EL},
        renderRowActions: ({row}) => (
            <Box sx={{display: 'flex', gap: '1rem'}}>
                <Tooltip title={<span style={{fontSize: '14px'}}>Edit </span>}><EditIcon
                    style={{cursor: 'pointer'}} onClick={() => table.setEditingRow(row)}/></Tooltip>
                {/*<Tooltip title={'View History Graph'}>*/}
                {/*    <VisibilityIcon onClick={() => openModal(row.original.serial_num)}*/}
                {/*                    style={{cursor: 'pointer'}}/>*/}
                {/*</Tooltip>*/}
            </Box>
        ),
        onEditingRowSave: ({table, values}) => {
            const body = {
                serial_num: values.serial_num,
                organisation: values.organisation,
                type: values.type,
                location: values.location,
                location2: values.location2,
                bin_weight_day: values.bin_weight_day,
                bin_lock: values.bin_lock,
                door_lock: values.door_lock,
                time_locked: values.time_locked,
                status: values.status,
                comments: values.comments
            }
            updateSystem(values._id, body)
                .then((_) => {
                    toast.success("Επιτυχής Ενημέρωση")
                })
                .finally(() => {
                    getSystemByPrefix(id)
                        .then((res) => {
                            setData(res.data.systems)
                            const trimCoords = res.data.coordinates.replace(/\s+/g, '')
                            const marks = [{
                                position: trimCoords.split(",").map(parseFloat)
                            }]
                            // const marks = res.data.map(item => {
                            //     const coords = item.coordinates.replace(/\s+/g, '')
                            //     return {
                            //         id: item._id,
                            //         position: coords.split(",").map(parseFloat),
                            //         name: item.location
                            //     };
                            // });
                            setMarkers(marks)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() =>
                            table.setEditingRow(null))
                })
        },
        renderTopToolbarCustomActions: ({table}) => (
            <></>
        ),
    });

    const handleSystemChange = (event) => {
        setLoading(true)
        navigate(`/detailed/${event.target.value}`);
        setSelectedSystem(event.target.value)
    }
   // console.log('Data passed to table:', data);  // Log the data passed to the table
   console.log(systems)
    return (
        <div style={{height: '92vh'}}>
            <Typography
                variant="h4"
                fontWeight="600"
                color={colors.grey[100]}
                style={{marginLeft: '20px'}}
            >
                Αναλυτική Συστήματος
                {systems &&
    <FormControl style={{ width: '80px', margin: '0 20px' }}>
        <NativeSelect
            onChange={handleSystemChange}
            inputProps={{
                name: 'Σύστημα',
                id: 'uncontrolled-native',
            }}
            value={selectedSystem}
        >
            {systems
                .slice() // Create a shallow copy to avoid mutating the original array
                .sort((a, b) => a.prefix.localeCompare(b.prefix)) // Sort alphabetically by prefix
                .map((system) => (
                    <option key={system.number} value={system.prefix}>
                        {system.prefix}
                    </option>
                ))}
        </NativeSelect>
    </FormControl>
}

            </Typography>
            <Box m="20px">
                <Box
                    display={smScreen ? "flex" : "block"}
                    flexDirection={smScreen ? "row" : "column"}
                    justifyContent={smScreen ? "space-between" : "start"}
                    alignItems={smScreen ? "center" : "start"}
                    m="10px 0"
                >
                </Box>

                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid xs={12} sm={12} md={8} lg={12}
                          container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                        <Grid xs={12}>
                            <Box backgroundColor={colors.primary[400]} style={{borderRadius: '10px', width: '85vw'}}>
                                <Box
                                    mt="25px"
                                    p="30px"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Box style={{width: '500px', margin: '0 20px'}}>
                                    <MapContainer 
    center={athensCenter} // Initial center
    zoom={zoom} 
    style={{height: '300px', width: '100%', borderRadius: '10px'}}
>
    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    <CenterUpdater center={athensCenter} /> {/* Dynamically update center */}
    {markers.map((marker, index) => (
        <Marker key={index} position={marker.position}>
            {/* Optional Popup */}
        </Marker>
    ))}
</MapContainer>

                                    </Box>
                                    <Box display="flex" justifyContent="space-between" alignItems="center"
                                         color={colors.grey[100]}
                                         p="15px"
                                         style={{
                                             position: 'relative',
                                             width: '500px',
                                             border: '1px solid gray',
                                             borderRadius: '10px'
                                         }}>
                                        <CustomPieChart
                                            options={{'d': 'Ημέρας', 'w': 'Εβδομάδας', 'm': 'Μήνα', 'all': 'Όλα'}}
                                            title={'Κατανομή Απορριμάτων'}
                                            subtitle={'(Φιλτραρισμένο)'}
                                            initData={initPieData}
                                            getPieData={getPieData}/>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" alignItems="center"
                                         color={colors.grey[100]}
                                         p="15px"
                                         style={{
                                             width: '500px',
                                             margin: '0 20px',
                                             border: '1px solid gray',
                                             borderRadius: '10px',
                                             position: 'relative',
                                         }}>
                                        <CustomBarChart options={typeOptions}
                                                        title={'Ημερήσια Χρήση'}
                                                        initData={initBarData}
                                                        getBarData={getBarData}
                                                        xAxis={barXAxis}/>
                                    </Box>
                                </Box>
                                <Box style={{padding: '10px', maxWidth: '100%'}}>
                                    <MaterialReactTable table={table}/>
                                </Box>
                                <Modal show={modalIsOpen}
                                       onHide={closeModal}
                                       size="lg"
                                       aria-labelledby="contained-modal-title-vcenter"
                                       centered>
                                    <CustomModal title={'Ιστορικά Δεδομένα'} closeModal={closeModal}>
                                        <div ref={chartRef} style={{width: '100%', height: '30vh'}}/>
                                    </CustomModal>
                                </Modal>
                            </Box>
                        </Grid>

                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default Detailed;
